<!--
 * @Author: your name
 * @Date: 2021-02-24 16:08:39
 * @LastEditTime: 2021-03-03 16:44:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \aiparkcity-acb\src\views\parkingGuidance\materialManage\index.vue
-->
<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <el-form :inline="true" label-position="right" :model="formInline">
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Material_Name')">
                <el-input v-model="formInline.materialName" placeholder="请输入素材名称"></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                style="margin-right: 5px"
                :loading="loading"
                @click="searchList(1)"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.button.add || $route.meta.authority.button.batchDelete"
          ></div>
          <div
            class="col_box h44"
            v-if="$route.meta.authority.button.add || $route.meta.authority.button.batchDelete"
          >
            <div class="col_left">
              <el-button
                type="primary"
                v-if="$route.meta.authority.button.add"
                @click="handleAdd({})"
                >添加素材</el-button
              >
              <el-button
                type="danger"
                v-if="$route.meta.authority.button.batchDelete"
                @click="handleBatchDelete"
                >删除素材</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <el-table :data="tableData" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center"> </el-table-column>
        <el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>
        <el-table-column
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
          v-for="item in tableCols"
          :key="item.prop"
          :sortable="item.sortable"
          :formatter="item.formatter"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="80">
          <template slot-scope="scope">
            <AuthorityComponent
              ComponentName="el-dropdown"
              align="center"
              @command="handleCommand($event, scope.row)"
              width="80"
            >
              <el-button type="text" size="small" style="padding: 0"
                >操作<i class="el-icon-arrow-down"
              /></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a" v-if="$route.meta.authority.button.edit"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item command="b" v-if="$route.meta.authority.button.view"
                  >查看</el-dropdown-item
                >
                <el-dropdown-item command="c" v-if="$route.meta.authority.button.delete"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </AuthorityComponent>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  exportExcelNew,
  dateFormat,
  //   setIndex
} from "@/common/js/public.js";

export default {
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 1,
      loading: false,
      formInline: {
        materialName: "",
      },
      tableCols: [
        {
          prop: "materialName",
          label: "素材名称",
        },
        {
          prop: "materialType",
          label: "类型",
          formatter: (r, c, v) => {
            let obj = {
              1: "空余车位数",
              2: "图片",
              4: "视频",
              8: "文本",
              10: "图片+文本",
              12: "视频+文本",
            };
            return obj[v];
          },
        },
        {
          prop: "playTime",
          label: "播放时长",
        },
        {
          prop: "creatorName",
          label: "创建者",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Creation_time"),
        },
      ],
      tableData: [],
      multipleSelection: [],
    };
  },
  watch: {},
  filters: {
    materialTypeText(type) {
      let obj = {
        1: "空余车位数",
        2: "图片",
        4: "视频",
        8: "文本",
        10: "图片+文本",
        12: "视频+文本",
      };
      return obj[type];
    },
  },
  methods: {
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.handleDetail(cmd, data);
      }
      if (cmd == "b") {
        this.handleDetail(cmd, data);
      }
      if (cmd == "c") {
        this.handleDelete(data.materialId);
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleAdd(data) {
      this.$router.push({
        path: "/addMaterial",
        query: data,
      });
    },
    handleDetail(cmd, data) {
      this.$axios
        .get("/acb/2.0/ydpMaterial/query", {
          data: {
            materialId: data.materialId,
          },
        })
        .then((res) => {
          let obj = res.value || {};
          if (cmd == "a") {
            this.handleAdd(obj);
          } else if (cmd == "b") {
            this.$router.push({
              path: "/materialDetail",
              query: obj,
            });
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchList(val);
    },
    handleBatchDelete() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: "请选择要删除的素材",
          type: "warning",
        });
        return false;
      }
      let ids = this.multipleSelection.map((item) => item.materialId);
      this.handleDelete(ids.join(","));
    },
    handleDelete(ids) {
      this.$confirm("您确定删除此条素材吗？", "", {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .post("/acb/2.0/ydpMaterial/delete", {
            data: {
              materialIds: ids,
            },
          })
          .then((res) => {
            this.searchList(this.page);
          })
          .catch((err) => {
            this.loading = false;
          });
      });
    },
    searchList(page) {
      this.page = page;
      this.loading = true;
      this.$axios
        .get("/acb/2.0/ydpMaterial/list", {
          data: {
            materialName: this.formInline.materialName,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.value.list;
          this.total = res.value.total - 0;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  beforeDestroy() {},
  created() {
    this.searchList(1);
  },
  mounted() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content
  .searchWrapper
    padding 22px 22px 0
    background #ffffff
  .pagerWrapper
    text-align right
    font-size 12px
    background #ffffff
    margin-top 0
    .export
      font-size 12px
      .iconfont
        margin-right 0
</style>
